import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Optionally, you can redirect to the main menu after a few seconds
    const timer = setTimeout(() => {
      navigate('/dashboard/my-courses');
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl text-center">
      <h2 className="text-2xl font-bold mb-4 text-green-600">Subscription Successful!</h2>
      <p className="mb-4">Thank you for subscribing. Your account has been upgraded to premium.</p>
      <p>You will be redirected to the main menu shortly.</p>
    </div>
  );
};

export default SubscriptionSuccess;