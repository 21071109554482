import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, X, Lock, Unlock, MoreVertical, Trash, Share, Users, Edit, Delete } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import api from '../../api';
import Navbar from './Navbar';
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from '../../UserContext';
import Loading from '../util/Loading';

const MyCourses = () => {
    const [courses, setCourses] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCourse, setEditingCourse] = useState(null);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isSharing, setIsSharing] = useState(false);
    const [sharingCourse, setSharingCourse] = useState(null);

    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();
    const { userProfile, loading: userLoading } = useUser();

    useEffect(() => {
        if (isAuthenticated && !userLoading) {
            fetchCourses();
        }
    }, [isAuthenticated, userLoading]);

    const fetchCourses = async () => {
        try {
            const response = await api.get('/courses/user-courses');
            setCourses(response.data);
        } catch (err) {
            setError('Failed to fetch courses: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleSelectCourse = (courseId) => {
        navigate(`/course/${courseId}`);
    };

    const handleEditCourse = (course) => {
        setEditingCourse(course);
        setIsEditing(true);
    };

    const handleDeleteCourse = async (courseId) => {
        if (window.confirm('Are you sure you want to delete this course? This action cannot be undone.')) {
            try {
                await api.delete(`/courses/${courseId}`);
                setSuccessMessage('Course deleted successfully');
                fetchCourses();
            } catch (err) {
                setError('Failed to delete course: ' + (err.response?.data?.message || err.message));
            }
        }
    };

    const handleShareCourse = (course) => {
        setSharingCourse(course);
        setIsSharing(true);
    };

    const handleRemoveAccess = async (courseId) => {
        if (window.confirm('Are you sure you want to remove your access to this course?')) {
            try {
                await api.post(`/courses/${courseId}/remove-access`);
                setSuccessMessage('Access removed successfully');
                fetchCourses(); // Refresh the course list
            } catch (err) {
                setError('Failed to remove access: ' + (err.response?.data?.message || err.message));
            }
        }
    };

    const handleCreateCourse = () => {
        setIsCreating(true);
    };

    const getUserPermissionForCourse = (course) => {
        if (!userProfile) return 'none';
        if (course.ownerId === userProfile.userId) return 'owner';
        if (course.writeUsers && course.writeUsers.includes(userProfile.userId)) return 'write';
        if (course.readUsers && course.readUsers.includes(userProfile.userId)) return 'read';
        return 'none';
    };

    if (isLoading || userLoading) {
        return <Loading />;
    }

    if (!isAuthenticated) {
        navigate('/login');
        return null;
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Navbar />
            <div className="max-w-6xl mx-auto mt-5 px-4">
                <h1 className="text-3xl font-bold mb-8">My Courses</h1>
                {error && <Alert variant="destructive" className="mb-4"><AlertDescription>{error}</AlertDescription></Alert>}
                {successMessage && <Alert className="mb-4"><AlertDescription>{successMessage}</AlertDescription></Alert>}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {courses.map((course) => (
                        <CourseCard
                            key={course.id}
                            course={course}
                            onClick={() => handleSelectCourse(course.id)}
                            onEdit={() => handleEditCourse(course)}
                            onDelete={
                                getUserPermissionForCourse(course) === 'owner'
                                    ? () => handleDeleteCourse(course.id)
                                    : () => handleRemoveAccess(course.id)
                            }
                            onShare={() => handleShareCourse(course)}
                            userPermission={getUserPermissionForCourse(course)}
                            currentUserId={userProfile.userId}  // Pass the current user's ID
                        />
                    ))}
                    <CreateCourseCard onClick={handleCreateCourse} />
                </div>
                {/* ... rest of the JSX ... */}
            </div>
            {isCreating && (
                <Modal onClose={() => setIsCreating(false)}>
                    <CreateCourseForm
                        onSuccess={(newCourseId) => {
                            setIsCreating(false);
                            fetchCourses();
                            navigate(`/course/${newCourseId}`);
                        }}
                        onCancel={() => setIsCreating(false)}
                    />
                </Modal>
            )}
            {isEditing && (
                <Modal onClose={() => setIsEditing(false)}>
                    <EditCourseForm
                        course={editingCourse}
                        onSuccess={() => {
                            setIsEditing(false);
                            fetchCourses();
                        }}
                        onCancel={() => setIsEditing(false)}
                    />
                </Modal>
            )}
            {isSharing && (
                <Modal onClose={() => setIsSharing(false)}>
                    <ShareCourseForm
                        course={sharingCourse}
                        onSuccess={() => {
                            setIsSharing(false);
                            fetchCourses();
                        }}
                        onCancel={() => setIsSharing(false)}
                    />
                </Modal>
            )}
        </div>
    );
};

const Modal = ({ children, onClose }) => {
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50" onClick={onClose}>
            <div className="bg-white p-8 rounded-lg shadow-lg w-96" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

const CourseGrid = ({ courses, onSelectCourse, onEditCourse, onDeleteCourse, onShareCourse, onCreateCourse }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {courses.map((course) => (
            <CourseCard
                key={course.id}
                course={course}
                onClick={() => onSelectCourse(course.id)}
                onEdit={() => onEditCourse(course)}
                onDelete={() => onDeleteCourse(course.id)}
                onShare={() => onShareCourse(course)}
            />
        ))}
        <CreateCourseCard onClick={onCreateCourse} />
    </div>
);

const CourseCard = ({ course, onClick, onEdit, onDelete, onShare, userPermission, currentUserId }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMenuClick = (e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete();
        setIsMenuOpen(false);
    };

    const handleShare = (e) => {
        e.stopPropagation();
        onShare();
        setIsMenuOpen(false);
    };

    const handleEdit = (e) => {
        e.stopPropagation();
        onEdit();
        setIsMenuOpen(false);
    };

    return (
        <div
            className="bg-white p-6 rounded-lg shadow-md relative cursor-pointer hover:shadow-lg transition-shadow duration-300"
            onClick={onClick}
        >
            <div className="absolute top-2 right-2 flex items-center space-x-2">
                {course.isPrivate ? (
                    <Lock className="text-gray-500" size={20} />
                ) : (
                    <Unlock className="text-gray-500" size={20} />
                )}
                <div className="relative" ref={menuRef}>
                    <button
                        onClick={handleMenuClick}
                        className="p-1 rounded-full hover:bg-gray-200"
                    >
                        <MoreVertical size={20} className="text-gray-500" />
                    </button>
                    {isMenuOpen && (
                        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {userPermission === 'owner' && (
                                    <>
                                        <button
                                            onClick={handleEdit}
                                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                            role="menuitem"
                                        >
                                            <Edit size={16} className="mr-2" /> Edit
                                        </button>
                                        <button
                                            onClick={handleShare}
                                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                            role="menuitem"
                                        >
                                            <Share size={16} className="mr-2" /> Share
                                        </button>
                                        <button
                                            onClick={handleDelete}
                                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                            role="menuitem"
                                        >
                                            <Trash size={16} className="mr-2" /> Delete
                                        </button>
                                    </>
                                )}
                                {userPermission === 'write' && (
                                    <>
                                        <button
                                            onClick={handleEdit}
                                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                            role="menuitem"
                                        >
                                            <Edit size={16} className="mr-2" /> Edit
                                        </button>
                                        <button
                                            onClick={handleShare}
                                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                            role="menuitem"
                                        >
                                            <Share size={16} className="mr-2" /> Share
                                        </button>
                                    </>
                                )}
                                {(userPermission === 'read' || userPermission === 'write') && (
                                    <button
                                        onClick={handleDelete}
                                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                        role="menuitem"
                                    >
                                        <Trash size={16} className="mr-2" /> Remove Course
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
            <p className="text-sm text-gray-600 mb-2">Subject: {course.subject}</p>
            <p className="text-sm text-gray-700 mb-4 line-clamp-2">{course.description}</p>
            {course.ownerId !== currentUserId && course.ownerUsername && (
                <div className="flex items-center text-sm text-gray-500 mt-2">
                    <span>{course.ownerUsername}</span>
                </div>
            )}
        </div>
    );
};

const CreateCourseCard = ({ onClick }) => (
    <div
        className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center justify-center cursor-pointer hover:bg-gray-300 transition duration-300"
        onClick={onClick}
    >
        <Plus size={40} className="mb-2 text-blue-600" />
        <span className="text-lg font-semibold">Create New Course</span>
    </div>
);

const CreateCourseForm = ({ onSuccess, onCancel }) => {
    const [title, setTitle] = useState('');
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [isPrivate, setIsPrivate] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleCreateCourse = async (e) => {
        e.preventDefault();
        if (!title.trim() || !subject.trim() || !description.trim()) {
            setError('Please fill in all fields.');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            const response = await api.post('/courses', {
                title,
                subject,
                description,
                isPrivate
            });
            onSuccess(response.data.id);
        } catch (err) {
            setError('Failed to create course: ' + (err.response?.data?.message || err.message));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <h3 className="text-2xl font-semibold mb-4">Create New Course</h3>
            <form onSubmit={handleCreateCourse}>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Course Title"
                    className="w-full p-3 mb-4 border rounded-md"
                    required
                />
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Subject"
                    className="w-full p-3 mb-4 border rounded-md"
                    required
                />
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Course Description"
                    className="w-full p-3 mb-4 border rounded-md"
                    rows="3"
                    required
                />
                <div className="flex items-center justify-between mb-4">
                    <label htmlFor="course-privacy" className="flex items-center cursor-pointer">
                        <div className="relative">
                            <input
                                type="checkbox"
                                id="course-privacy"
                                className="sr-only"
                                checked={isPrivate}
                                onChange={() => setIsPrivate(!isPrivate)}
                            />
                            <div className={`block w-14 h-8 rounded-full ${isPrivate ? 'bg-blue-500' : 'bg-gray-300'}`}></div>
                            <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${isPrivate ? 'transform translate-x-6' : ''}`}></div>
                        </div>
                        <div className="ml-3 text-gray-700 font-medium">
                            Make course private
                        </div>
                    </label>
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="mr-2 px-4 py-2 bg-gray-300 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Creating...' : 'Create Course'}
                    </button>
                </div>
            </form>
            {error && <p className="text-red-500 mt-4">{error}</p>}
        </>
    );
};

const EditCourseForm = ({ course, onSuccess, onCancel }) => {
    const [title, setTitle] = useState(course.title);
    const [subject, setSubject] = useState(course.subject);
    const [description, setDescription] = useState(course.description);
    const [isPrivate, setIsPrivate] = useState(course.isPrivate);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleEditCourse = async (e) => {
        e.preventDefault();
        if (!title.trim() || !subject.trim() || !description.trim()) {
            setError('Please fill in all fields.');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            await api.put(`/courses/${course.id}`, {
                title,
                subject,
                description,
                isPrivate
            });
            onSuccess();
        } catch (err) {
            setError('Failed to update course: ' + (err.response?.data?.message || err.message));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <h3 className="text-2xl font-semibold mb-4">Edit Course</h3>
            <form onSubmit={handleEditCourse}>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Course Title"
                    className="w-full p-3 mb-4 border rounded-md"
                    required
                />
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Subject"
                    className="w-full p-3 mb-4 border rounded-md"
                    required
                />
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Course Description"
                    className="w-full p-3 mb-4 border rounded-md"
                    rows="3"
                    required
                />
                <div className="flex items-center justify-between mb-4">
                    <label htmlFor="course-privacy" className="flex items-center cursor-pointer">
                        <div className="relative">
                            <input
                                type="checkbox"
                                id="course-privacy"
                                className="sr-only"
                                checked={isPrivate}
                                onChange={() => setIsPrivate(!isPrivate)}
                            />
                            <div className={`block w-14 h-8 rounded-full ${isPrivate ? 'bg-blue-500' : 'bg-gray-300'}`}></div>
                            <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${isPrivate ? 'transform translate-x-6' : ''}`}></div>
                        </div>
                        <div className="ml-3 text-gray-700 font-medium">
                            Make course private
                        </div>
                    </label>
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="mr-2 px-4 py-2 bg-gray-300 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Updating...' : 'Update Course'}
                    </button>
                </div>
            </form>
            {error && <p className="text-red-500 mt-4">{error}</p>}
        </>
    );
};

const ShareCourseForm = ({ course, onSuccess, onCancel }) => {
    const [email, setEmail] = useState('');
    const [permission, setPermission] = useState('read');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [sharedUsers, setSharedUsers] = useState([]);

    useEffect(() => {
        fetchSharedUsers();
    }, []);

    const fetchSharedUsers = async () => {
        try {
            const response = await api.get(`/courses/${course.id}/shared-users`);
            setSharedUsers(response.data);
        } catch (err) {
            console.error('Failed to fetch shared users:', err);
        }
    };

    const handleShareCourse = async (e) => {
        e.preventDefault();
        if (!email.trim()) {
            setError('Please enter an email address.');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            await api.post(`/courses/${course.id}/share`, {
                email,
                permission
            });
            setEmail('');
            fetchSharedUsers();
            onSuccess();
        } catch (err) {
            setError('Failed to share course: ' + (err.response?.data?.message || err.message));
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveShare = async (userId) => {
        try {
            await api.delete(`/courses/${course.id}/share`, { data: { userId } });
            fetchSharedUsers();
        } catch (err) {
            setError('Failed to remove share: ' + (err.response?.data?.message || err.message));
        }
    };

    return (
        <>
            <h3 className="text-2xl font-semibold mb-4">Share Course</h3>
            <form onSubmit={handleShareCourse}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="User's Email"
                    className="w-full p-3 mb-4 border rounded-md"
                    required
                />
                <div className="mb-4">
                    <label className="block mb-2">Permission:</label>
                    <select
                        value={permission}
                        onChange={(e) => setPermission(e.target.value)}
                        className="w-full p-3 border rounded-md"
                    >
                        <option value="read">Read</option>
                        <option value="write">Write</option>
                    </select>
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="mr-2 px-4 py-2 bg-gray-300 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Sharing...' : 'Share Course'}
                    </button>
                </div>
            </form>
            {error && <p className="text-red-500 mt-4">{error}</p>}

            <div className="mt-8">
                <h4 className="text-xl font-semibold mb-4">Shared With:</h4>
                {sharedUsers.map((user) => (
                    <div key={user.id} className="flex justify-between items-center mb-2">
                        <span>{user.email} ({user.permission})</span>
                        <button
                            onClick={() => handleRemoveShare(user.id)}
                            className="px-2 py-1 bg-red-500 text-white rounded text-sm"
                        >
                            Remove
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MyCourses; 