import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Search, Home, Grid, User, Settings, HelpCircle, LogOut, Star, Menu, X } from 'lucide-react';
import { useUser } from '../../UserContext';

const Navbar = () => {
  const { logout } = useAuth0();
  const { user, userProfile } = useUser();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  const NavIcon = ({ icon: Icon, label, to, dropdownName, onClick }) => (
    <div className="relative group">
      {to ? (
        <Link to={to} className="flex flex-col items-center text-gray-600 hover:text-purple-700">
          <Icon size={24} />
          <span className="mt-1 text-xs lg:hidden">{label}</span>
        </Link>
      ) : (
        <button
          onClick={() => {
            onClick();
            toggleDropdown(dropdownName);
          }}
          className="flex flex-col items-center text-gray-600 hover:text-purple-700"
        >
          <Icon size={24} />
          <span className="mt-1 text-xs lg:hidden">{label}</span>
        </button>
      )}
      <span className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none hidden md:block">
        {label}
      </span>
    </div>
  );

  // const UpgradeButton = () => {
  //   if (userProfile && !userProfile.isPaidUser) {
  //     return (
  //       <Link to="/subscribe" className="py-2 px-4 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition duration-300 transform hover:scale-105 active:scale-95">
  //         Upgrade
  //       </Link>
  //     );
  //   }
  //   return null;
  // };

  return (
    <>
      {/* Desktop Navbar */}
      <div className="hidden md:block fixed top-0 left-0 right-0 bg-gray-100 z-10">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex-shrink-0">
            <svg className="w-32 md:w-48" viewBox="0 0 193 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <svg className="w-32 md:w-48" viewBox="0 0 193 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M160.281 26.752V8.36084H163.382V10.7132C163.489 11.034 163.809 10.8201 164.023 10.6063C164.192 10.4372 165.093 9.75087 165.841 9.32317C166.589 8.89547 167.766 8.36084 169.797 8.36084C176.042 8.36084 178.03 13.3507 178.244 15.8456V26.752H175.037V16.4872C174.823 14.8833 173.411 11.6755 169.476 11.6755C165.542 11.6755 163.845 14.8833 163.489 16.4872V26.752H160.281Z" fill="#282A31"></path>
                <path d="M175.037 26.752V14.2417C175.206 14.0727 177.068 11.4617 178.993 10.0716C180.917 8.68162 182.521 8.36084 184.553 8.36084C190.797 8.36084 192.786 13.3507 193 15.8456V26.752H189.792V16.4872C189.578 14.8833 188.167 11.6755 184.232 11.6755C180.297 11.6755 178.601 14.8833 178.244 16.4872V26.752H175.037Z" fill="#282A31"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M147.664 27.2866C152.979 27.2866 157.287 22.9781 157.287 17.6633C157.287 12.3485 152.979 8.04004 147.664 8.04004C142.349 8.04004 138.04 12.3485 138.04 17.6633C138.04 22.9781 142.349 27.2866 147.664 27.2866ZM147.769 23.9028C151.157 23.9028 153.903 21.1568 153.903 17.7693C153.903 14.3819 151.157 11.6358 147.769 11.6358C144.382 11.6358 141.636 14.3819 141.636 17.7693C141.636 21.1568 144.382 23.9028 147.769 23.9028Z" fill="#282A31"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M126.706 27.2866C132.021 27.2866 136.33 22.9781 136.33 17.6633C136.33 12.3485 132.021 8.04004 126.706 8.04004C121.392 8.04004 117.083 12.3485 117.083 17.6633C117.083 22.9781 121.392 27.2866 126.706 27.2866ZM126.812 23.9028C130.2 23.9028 132.946 21.1568 132.946 17.7693C132.946 14.3819 130.2 11.6358 126.812 11.6358C123.425 11.6358 120.679 14.3819 120.679 17.7693C120.679 21.1568 123.425 23.9028 126.812 23.9028Z" fill="#282A31"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M105.749 27.2866C111.064 27.2866 115.372 22.9781 115.372 17.6633C115.372 12.3485 111.064 8.04004 105.749 8.04004C100.434 8.04004 96.1258 12.3485 96.1258 17.6633C96.1258 22.9781 100.434 27.2866 105.749 27.2866ZM105.855 23.9028C109.242 23.9028 111.988 21.1568 111.988 17.7693C111.988 14.3819 109.242 11.6358 105.855 11.6358C102.467 11.6358 99.7212 14.3819 99.7212 17.7693C99.7212 21.1568 102.467 23.9028 105.855 23.9028Z" fill="#282A31"></path>
                <path d="M96.1257 3.1214V17.5563L99.975 11.7823V0.769043L96.1257 3.1214Z" fill="#282A31"></path>
                <path d="M89.2825 10.0716V26.752H93.0249V8.36084L89.2825 10.0716Z" fill="#282A31"></path>
                <circle cx="91.2072" cy="3.44218" r="2.5662" fill="#282A31"></circle>
                <path d="M67.6837 26.752V8.36084H70.7845V10.7132C70.8914 11.034 71.2122 10.8201 71.426 10.6063C71.5951 10.4372 72.4953 9.75087 73.2438 9.32317C73.9922 8.89547 75.1684 8.36084 77.2 8.36084C83.4444 8.36084 85.4332 13.3507 85.6471 15.8456V26.752H82.4393V16.4872C82.2255 14.8833 80.8141 11.6755 76.8792 11.6755C72.9444 11.6755 71.2478 14.8833 70.8914 16.4872V26.752H67.6837Z" fill="#282A31"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M55.6011 26.4312C60.6206 26.4312 64.6897 22.3621 64.6897 17.3425C64.6897 12.323 60.6206 8.25391 55.6011 8.25391C50.5816 8.25391 46.5125 12.323 46.5125 17.3425C46.5125 22.3621 50.5816 26.4312 55.6011 26.4312ZM55.708 22.9027C58.7788 22.9027 61.2681 20.4134 61.2681 17.3426C61.2681 14.2719 58.7788 11.7825 55.708 11.7825C52.6372 11.7825 50.1479 14.2719 50.1479 17.3426C50.1479 20.4134 52.6372 22.9027 55.708 22.9027Z" fill="#282A31"></path>
                <path d="M60.6266 27.8215C60.1989 26.795 59.0227 26.1107 58.4881 25.8968L61.375 24.0791C61.8384 24.3999 62.8292 25.127 63.0858 25.4691C63.4066 25.8968 64.4759 26.4315 64.4759 29.5323C64.4759 32.6331 62.3374 34.8785 61.482 35.5201C60.6266 36.1617 58.595 37.2309 55.9218 37.2309C53.2487 37.2309 51.3241 36.1617 50.2548 35.3062C49.1856 34.4508 47.7955 32.9539 47.7955 30.8154C47.7955 28.6769 48.0094 28.0353 48.4371 27.7146C48.8648 27.3938 48.9717 27.2869 50.041 27.2869C50.8964 27.2869 51.6092 27.7146 51.8587 27.9284C52.0369 27.9997 52.2864 28.2706 51.8587 28.7838C51.431 29.2971 51.1815 30.2095 51.1102 30.6015C51.1102 32.9111 54.1754 33.5598 55.708 33.5954C56.4921 33.7024 58.3597 33.6168 59.5573 32.4193C61.0543 30.9223 61.1612 29.1046 60.6266 27.8215Z" fill="#282A31"></path>
                <path d="M61.9097 9.75106C61.4301 9.88809 61.5176 10.4639 61.6958 10.7134C60.484 10.0362 58.1887 8.72458 58.7019 8.89566C59.3435 9.10951 59.6643 8.68181 59.8781 8.14718C60.092 7.61256 60.4127 6.65023 60.8404 6.00868C61.2681 5.36713 62.2305 4.61865 63.2997 4.61865C64.369 4.61865 65.0105 5.47405 65.0105 6.22253C65.0105 6.97101 64.5828 7.82641 63.9413 8.57489C63.2997 9.32336 62.6582 9.53721 61.9097 9.75106Z" fill="#282A31"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M34.9645 27.2866C40.2793 27.2866 44.5878 22.9781 44.5878 17.6633C44.5878 12.3485 40.2793 8.04004 34.9645 8.04004C29.6497 8.04004 25.3412 12.3485 25.3412 17.6633C25.3412 22.9781 29.6497 27.2866 34.9645 27.2866ZM35.0703 23.9028C38.4577 23.9028 41.2038 21.1568 41.2038 17.7693C41.2038 14.3819 38.4577 11.6358 35.0703 11.6358C31.6828 11.6358 28.9367 14.3819 28.9367 17.7693C28.9367 21.1568 31.6828 23.9028 35.0703 23.9028Z" fill="#282A31"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M24.0446 21.095C21.7843 24.868 17.6561 27.3935 12.938 27.3935C5.79252 27.3935 0 21.601 0 14.4555C0 7.3101 5.79252 1.51758 12.938 1.51758C17.5283 1.51758 21.5602 3.90812 23.8569 7.51217L20.7606 9.65007C19.1554 7.0782 16.3 5.36702 13.0449 5.36702C8.02537 5.36702 3.95625 9.43615 3.95625 14.4557C3.95625 19.4752 8.02537 23.5443 13.0449 23.5443C16.3253 23.5443 19.1998 21.8064 20.7978 19.2011L24.0446 21.095Z" fill="#282A31"></path>
              </svg>
            </svg>
          </Link>

          <div className="flex items-center space-x-6">
            {/* <UpgradeButton /> */}

            <div className="flex items-center space-x-4" ref={dropdownRef}>
              {/* <NavIcon icon={Search} label="Search" /> */}
              <NavIcon icon={Home} label="Home" to="/dashboard/my-courses" />
              <NavIcon icon={Grid} label="Browse" to="/dashboard/browse-courses" />
              <div className="relative">
                <NavIcon
                  icon={User}
                  label="Profile"
                  dropdownName="profile"
                  onClick={() => { }}
                />
                <div
                  className={`
                    absolute right-0 mt-3 w-64 bg-white rounded-md shadow-lg py-1 z-10 
                    transition-all duration-200 origin-top-right
                    ${activeDropdown === 'profile'
                      ? 'opacity-100 scale-100 translate-y-0'
                      : 'opacity-0 scale-95 -translate-y-2 pointer-events-none'}
                  `}
                >
                  <div className="px-4 py-3 border-b border-gray-200">
                    <div className="flex items-center space-x-3">
                      <img src={user?.picture || 'https://via.placeholder.com/40'} alt="Profile" className="w-10 h-10 rounded-full" />
                      <div>
                        <p className="text-sm font-semibold text-gray-700">{user?.name || 'User Name'}</p>
                        <p className="text-xs text-gray-500">{user?.email || 'user@example.com'}</p>
                      </div>
                    </div>
                  </div>
                  <Link to="/dashboard/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    <Settings className="inline-block w-4 h-4 mr-2" />
                    Account Settings
                  </Link>
                  {/* <Link to="/help" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    <HelpCircle className="inline-block w-4 h-4 mr-2" />
                    Help & Support
                  </Link> */}
                  {/* {userProfile && !userProfile.isPaidUser && (
                    <Link to="/subscribe" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      <Star className="inline-block w-4 h-4 mr-2" />
                      Upgrade to Pro
                    </Link>
                  )} */}
                  <button
                    onClick={() => logout({ returnTo: window.location.origin })}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <LogOut className="inline-block w-4 h-4 mr-2" />
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-gray-100 z-10">
        <div className="flex justify-around items-center py-2">
          <NavIcon icon={Home} label="Home" to="/dashboard/my-courses" />
          {/* <NavIcon icon={Search} label="Search" to="/dashboard/my-courses" /> */}
          <NavIcon icon={Grid} label="Browse" to="/dashboard/browse-courses" />
          <NavIcon icon={User} label="Profile" to="/dashboard/profile" />
        </div>
      </div>

      {/* Mobile Menu Button */}
      <button
        className="md:hidden fixed top-4 right-4 z-50 p-2"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        {isMobileMenuOpen ? <X size={28} /> : <Menu size={28} />}
      </button>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed inset-0 bg-white z-40 pt-20 px-6">
          <div className="flex flex-col items-center space-y-6">
            {/* <UpgradeButton /> */}
            <Link to="/dashboard/profile" className="text-gray-700 text-lg py-2">Account Settings</Link>
            <Link to="/help" className="text-gray-700 text-lg py-2">Help & Support</Link>
            {/* {userProfile && !userProfile.isPaidUser && (
              <Link to="/subscribe" className="text-gray-700 text-lg py-2">Upgrade to Pro</Link>
            )} */}
            <button
              onClick={() => logout({ returnTo: window.location.origin })}
              className="text-gray-700 text-lg py-2 text-left"
            >
              Logout
            </button>
          </div>
        </div>
      )}

      {/* Spacer for content (adjust based on your needs) */}
      <div className='h-20 md:block hidden' />
    </>
  );
}

export default Navbar;