// dashboardUtils.js
import api from '../../api';

export const fetchCourseData = async (courseId, setCourse, setModules, setLoading, setError, setUserPermission) => {
    try {
      setLoading(true);
      const response = await api.get(`/courses/${courseId}`);
      const courseData = response.data;
      
      setCourse(courseData);
      
      if (Array.isArray(courseData.structure)) {
        const formattedModules = courseData.structure.map(module => ({
          id: module.id,
          type: module.type,
          title: module.title,
          order: module.order,
          resourceIds: module.resourceIds || []
        }));
  
        formattedModules.sort((a, b) => a.order - b.order);
        setModules(formattedModules);
      } else {
        console.error('Structure is not an array:', courseData.structure);
        setError('Invalid course structure');
      }
      
      // Set the user permission from the API response
      setUserPermission(courseData.userPermission);
      
      setLoading(false);
    } catch (err) {
      console.error('Error fetching course data:', err);
      setError('Failed to fetch course data');
      setLoading(false);
    }
  };

export const addNewModule = (modules, setModules) => {
    const newModule = { 
        id: Date.now().toString(),
        type: 'module',
        title: 'New Module',
        order: modules.length,
        resourceIds: []
    };
    setModules([...modules, newModule]);
};