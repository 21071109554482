import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronRight, CheckCircle, Circle, BookOpen, MinusCircle } from 'lucide-react';
import api from '../api';

const Progress = () => {
    const { courseId } = useParams();
    const navigate = useNavigate();
    const [toc, setToc] = useState([]);
    const [progress, setProgress] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openSections, setOpenSections] = useState({});

    useEffect(() => {
        fetchTocAndProgress();
    }, [courseId]);

    const fetchTocAndProgress = async () => {
        try {
            setLoading(true);
            setError('');
            const [tocResponse, progressResponse] = await Promise.all([
                api.get(`/courses/${courseId}/toc`),
                api.get(`/courses/${courseId}/progress`)
            ]);
            setToc(tocResponse.data);
            setProgress(progressResponse.data);
            const initialOpenSections = {};
            const flattenToc = (items, parentIndex = '') => {
                items.forEach((item, index) => {
                    const itemId = parentIndex ? `${parentIndex}-${index + 1}` : `${index + 1}`;
                    initialOpenSections[itemId] = false;
                    if (item.children) {
                        flattenToc(item.children, itemId);
                    }
                });
            };
            flattenToc(tocResponse.data);
            setOpenSections(initialOpenSections);
        } catch (err) {
            setError(`Failed to fetch data: ${err.response?.data?.error || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const toggleSection = (id) => {
        setOpenSections(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleSectionClick = (item, id) => {
        navigate(`/course/${courseId}/learn`, { 
          state: { 
            sectionId: id,
            sectionTitle: item.title, 
            sectionPage: item.page 
          } 
        });
    };

    const calculateSectionProgress = (item, id) => {
        if (!item.children || item.children.length === 0) {
            return progress[id] ? 'completed' : 'not-started';
        }

        const childProgress = item.children.map((child, index) => 
            calculateSectionProgress(child, `${id}-${index + 1}`)
        );

        if (childProgress.every(p => p === 'completed')) {
            return 'completed';
        } else if (childProgress.some(p => p === 'completed' || p === 'in-progress')) {
            return 'in-progress';
        }

        return 'not-started';
    };

    const renderTocItem = (item, id, level = 0) => {
        const sectionProgress = calculateSectionProgress(item, id);
        
        return (
            <div key={id} className={`mb-2 ${level > 0 ? 'ml-6' : ''}`}>
                <div className={`flex items-center p-2 rounded-lg 
                    hover:bg-gray-100 transition-colors duration-200`}>
                    {item.children && item.children.length > 0 ? (
                        <button
                            onClick={() => toggleSection(id)}
                            className="mr-2 focus:outline-none text-gray-500 hover:text-gray-700"
                        >
                            {openSections[id] ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                        </button>
                    ) : (
                        <BookOpen size={20} className="mr-2 text-gray-400" />
                    )}
                    <button
                        onClick={() => handleSectionClick(item, id)}
                        className={`flex-grow text-left ${level === 0 ? 'font-semibold' : ''} 
                            ${sectionProgress === 'completed' ? 'text-green-600' : 
                              sectionProgress === 'in-progress' ? 'text-yellow-600' : 
                              'text-gray-700'} hover:text-blue-600`}
                    >
                        {item.title}
                    </button>
                    {sectionProgress === 'completed' ? (
                        <CheckCircle size={20} className="text-green-500 ml-2" />
                    ) : sectionProgress === 'in-progress' ? (
                        <MinusCircle size={20} className="text-yellow-500 ml-2" />
                    ) : (
                        <Circle size={20} className="text-gray-300 ml-2" />
                    )}
                </div>
                {openSections[id] && item.children && item.children.length > 0 && (
                    <div className="mt-2 border-l-2 border-gray-200">
                        {item.children.map((child, index) => renderTocItem(child, `${id}-${index + 1}`, level + 1))}
                    </div>
                )}
            </div>
        );
    };

    const calculateProgress = (tocArray) => {
        let totalSections = 0;
        let completedSections = 0;

        const countSections = (items) => {
            items.forEach((item) => {
                if (!item.children || item.children.length === 0) {
                    totalSections++;
                    if (calculateSectionProgress(item, item.id) === 'completed') {
                        completedSections++;
                    }
                } else {
                    countSections(item.children);
                }
            });
        };

        countSections(tocArray);

        return {
            percentage: totalSections > 0 ? (completedSections / totalSections) * 100 : 0,
            completed: completedSections,
            total: totalSections
        };
    };

    if (loading) return (
        <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
        </div>
    );
    if (error) return (
        <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
            <p className="font-bold">Error</p>
            <p>{error}</p>
        </div>
    );

    const { percentage, completed, total } = calculateProgress(toc);

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">Course Progress</h2>
            <div className="mb-8">
                <div className="flex items-center mb-2">
                    <div className="flex-grow">
                        <div className="h-4 w-full bg-gray-200 rounded-full overflow-hidden">
                            <div
                                className="h-full bg-blue-500 rounded-full transition-all duration-500 ease-out"
                                style={{ width: `${percentage}%` }}
                            ></div>
                        </div>
                    </div>
                    <span className="ml-4 font-semibold text-lg text-blue-600">{percentage.toFixed(1)}%</span>
                </div>
                <div className="text-center text-sm text-gray-600">
                    <span className="font-medium">{completed}</span> out of <span className="font-medium">{total}</span> sections completed
                </div>
            </div>
            {toc.length > 0 ? (
                <div className="space-y-4 bg-gray-50 p-4 rounded-lg">
                    {toc.map((item, index) => renderTocItem(item, `${index + 1}`))}
                </div>
            ) : (
                <p className="text-center text-gray-500 italic">No table of contents found for this course.</p>
            )}
        </div>
    );
};

export default Progress;