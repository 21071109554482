import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useApi } from './api';
import { UserProvider } from './UserContext';
import Sidebar from './components/Sidebar';
import Learn from './components/Course/Learn';
import Progress from './components/Progress';
import Ask from './components/Course/Ask';
import Subscribe from './components/Subscribe';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import LandingPage from './components/LandingPage';
import MyCourses from './components/MainMenu/MyCourses';
import BrowseCourses from './components/MainMenu/BrowseCourses';
import Profile from './components/MainMenu/Profile';
import Loading from './components/util/Loading';
import CourseDashboard from './components/Course/Dashboard';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  useApi();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

const LoginRedirect = () => {
  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <div>Redirecting to login...</div>;
};

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard/my-courses" /> : <LoginRedirect />} />
          <Route path="/dashboard/my-courses" element={<PrivateRoute><MyCourses /></PrivateRoute>} />

          <Route path="/dashboard/browse-courses" element={<PrivateRoute><BrowseCourses /></PrivateRoute>} />
          <Route path="/dashboard/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/subscribe" element={<PrivateRoute><Subscribe /></PrivateRoute>} />
          <Route path="/subscription-success" element={<PrivateRoute><SubscriptionSuccess /></PrivateRoute>} />
          <Route path="/course/:courseId/*" element={
            <PrivateRoute>
              <div className="flex h-screen bg-gray-100">
                <Sidebar />
                <main className="flex-1 overflow-y-auto">
                  <Routes>
                    <Route index element={<CourseDashboard />} />
                    <Route path="learn" element={<Learn />} />
                    <Route path="learn/:sectionId" element={<Learn />} />
                    <Route path="progress" element={<Progress />} />
                    <Route path="ask" element={<Ask />} />
                  </Routes>
                </main>
              </div>
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </UserProvider>
  );
};

const AppWithAuth = () => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/app`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid profile email'
    }}
  >
    <App />
  </Auth0Provider>
);

export default AppWithAuth;