import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, Plus, Book, Trash2, FileText, Link as LinkIcon, Video, Edit3, ClipboardList, BookOpen, HelpCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../../api';
import AddResourceModal from './AddResourceModal';

const Module = ({ module, moduleNumber, onAddResource, onDeleteModule, onCancelNewModule, courseId, updateModuleResources, setError, saveNewModule, isNew, userPermission }) => {
    const [expanded, setExpanded] = useState(isNew);
    const [editing, setEditing] = useState(isNew);
    const [editTitle, setEditTitle] = useState(module.title);
    const [showResourceDropdown, setShowResourceDropdown] = useState(false);
    const [resources, setResources] = useState(module.resources || []);
    const [loadingResources, setLoadingResources] = useState(false);
    const [loadResources, setLoadResources] = useState(!isNew);
    const [showAddResourceModal, setShowAddResourceModal] = useState(false);
    const [resourceType, setResourceType] = useState(null);
    const editInputRef = useRef(null);
    const dropdownRef = useRef(null);
    const addButtonRef = useRef(null);
    const canEdit = userPermission === 'owner' || userPermission === 'write';


    useEffect(() => {
        if (editing && editInputRef.current) {
            editInputRef.current.focus();
        }
    }, [editing]);

    useEffect(() => {
        setEditTitle(module.title);
        setResources(module.resources || []);
    }, [module]);

    useEffect(() => {
        if (expanded && !isNew && loadResources) {
            fetchResources();
        }
    }, [expanded, isNew, loadResources]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchResources = async () => {
        if (!loadResources || isNew) return;

        setLoadingResources(true);
        try {
            const moduleResponse = await api.get(`/courses/${courseId}/modules/${module.id}`);
            const updatedModule = moduleResponse.data;

            setResources(updatedModule.resources || []);
            updateModuleResources(module.id, updatedModule);
            setLoadResources(false);
        } catch (err) {
            console.error('Error fetching resources:', err);
            setError('Failed to fetch resources');
        } finally {
            setLoadingResources(false);
        }
    };

    const toggleExpansion = (event) => {
        if (!editing) {
            setExpanded(!expanded);
            event.stopPropagation();
        }
    };

    const toggleEditMode = (event) => {
        if (!canEdit) return;
        if (editing) {
            updateModule();
        } else {
            setEditTitle(module.title);
            setEditing(true);
            setExpanded(true);
        }
        event.stopPropagation();
    };

    const updateModule = async () => {
        try {
            let updatedModule;
            if (isNew) {
                updatedModule = await saveNewModule(module.id, editTitle);
            } else {
                const response = await api.put(`/courses/${courseId}/modules/${module.id}`, {
                    title: editTitle
                });
                updatedModule = response.data;
            }
            updateModuleResources(updatedModule.id, updatedModule);
            setEditing(false);
            setLoadResources(true);
        } catch (err) {
            setError('Failed to update module');
        }
    };

    const handleEditKeyPress = (event) => {
        if (event.key === 'Enter') {
            updateModule();
        }
    };

    const deleteResource = async (resourceId) => {
        if (!canEdit) return;
        try {
            await api.delete(`/courses/${courseId}/modules/${module.id}/resources/${resourceId}`);
            setLoadResources(true);
            fetchResources();
        } catch (err) {
            setError('Failed to delete resource');
        }
    };

    const handleResourceAdded = (moduleId, updatedModuleData) => {
        setLoadResources(true);
        fetchResources();
    };

    const toggleResourceDropdown = (e) => {
        if (editing) return;
        e.stopPropagation();
        setShowResourceDropdown(!showResourceDropdown);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
            addButtonRef.current && !addButtonRef.current.contains(event.target)) {
            setShowResourceDropdown(false);
        }
    };

    const handleCancel = () => {
        if (isNew) {
            onCancelNewModule(module.id);
        } else {
            setEditing(false);
            setEditTitle(module.title);
        }
    };

    const renderEmptyState = () => (
        <button
            onClick={toggleResourceDropdown}
            className={`w-full py-8 mt-4 text-xl font-semibold ${
                editing 
                    ? 'text-gray-400 bg-gray-100 cursor-not-allowed' 
                    : 'text-blue-500 bg-blue-100 hover:bg-blue-200'
            } rounded-lg transition-colors flex items-center justify-center`}
            disabled={editing}
        >
            {editing ? 'Cannot add resources while editing' : 'Module is empty, add resources'}
            <Plus size={24} className="ml-2" />
        </button>
    );

    const renderAddButton = () => {
        if (!canEdit) return null;
        return (
            <button
                onClick={toggleResourceDropdown}
                className="px-1 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded flex items-center"
            >
                <Plus size={20} />
            </button>
        );
    };

    const renderResourceIcon = (type) => {
        switch (type) {
            case 'pdf': return <FileText size={16} />;
            case 'link': return <LinkIcon size={16} />;
            case 'video': return <Video size={16} />;
            case 'notes': return <Edit3 size={16} />;
            case 'test': return <ClipboardList size={16} />;
            case 'flashcards': return <BookOpen size={16} />;
            default: return <FileText size={16} />;
        }
    };

    return (
        <div className="mb-4 border rounded-lg bg-gray-200 overflow-hidden">
            <div
                className="flex justify-between items-center p-4 cursor-pointer"
                onClick={toggleExpansion}
            >
                <div className="flex-grow flex items-center">
                    {moduleNumber !== null && (
                        <span className="text-xl font-semibold mr-2">{moduleNumber}:</span>
                    )}
                    {editing ? (
                        <input
                            ref={editInputRef}
                            type="text"
                            value={editTitle}
                            onChange={(e) => setEditTitle(e.target.value)}
                            onKeyPress={handleEditKeyPress}
                            className="text-xl font-semibold p-1 border rounded w-full"
                            onClick={(e) => e.stopPropagation()}
                            placeholder="Enter module title"
                        />
                    ) : (
                        <h2 className="text-xl font-semibold">
                            {module.title || 'Untitled Section'}
                        </h2>
                    )}
                </div>

                <div className="px-4">
                    {expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>

                {!editing && !isNew && (
                    <div className="relative" ref={addButtonRef}>
                        {renderAddButton()}
                        {showResourceDropdown && (
                            <div
                                ref={dropdownRef}
                                className="absolute z-50 w-48 mt-2 right-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                                style={{
                                    position: 'fixed',
                                    top: addButtonRef.current.getBoundingClientRect().bottom + window.scrollY,
                                    right: window.innerWidth - addButtonRef.current.getBoundingClientRect().right,
                                }}
                            >
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setResourceType('text');
                                            setShowAddResourceModal(true);
                                            setShowResourceDropdown(false);
                                        }}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                    >
                                        Upload Text
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setResourceType('file');
                                            setShowAddResourceModal(true);
                                            setShowResourceDropdown(false);
                                        }}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                    >
                                        Upload File
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <AnimatePresence initial={false}>
                {expanded && (
                    <motion.div
                        key="content"
                        initial="collapsed"
                        animate="expanded"
                        exit="collapsed"
                        variants={{
                            expanded: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <div className="px-4 pb-4">
                            {loadingResources ? (
                                <div className="flex justify-center items-center">
                                    <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-500"></div>
                                </div>
                            ) : resources.length === 0 ? (
                                canEdit ? renderEmptyState() : <p>Module is empty</p>
                            ) : (
                                resources.map((resource) => (
                                    <div key={resource.id} className="flex items-center justify-between text-sm text-gray-600 p-2 rounded hover:bg-gray-100">
                                        <div className="flex items-center">
                                            {renderResourceIcon(resource.type)}
                                            <span className="ml-2">{resource.name || resource.title || resource.filename || 'Unnamed Resource'}</span>
                                        </div>
                                        <div className="flex items-center">
                                            <Link
                                                to={`/course/${courseId}/ask?moduleId=${module.id}&resourceId=${resource.id}`}
                                                className="mr-2 px-3 py-2 bg-gray-500 text-white rounded-md inline-flex items-center hover:bg-blue-700 transition-colors"
                                                title="Ask questions about this resource"
                                            >
                                                <HelpCircle size={16} className="mr-1" />
                                                <span>Ask</span>
                                            </Link>
                                            <Link
                                                to={`/course/${courseId}/learn?resource=${resource.id}`}
                                                className="mr-2 px-3 py-2 bg-gray-500 text-white rounded-md inline-flex items-center hover:bg-blue-700 transition-colors"
                                                title="Learn about this resource"
                                            >
                                                <Book size={16} className="mr-1" />
                                                <span>Learn</span>
                                            </Link>
                                            {canEdit && (
                                                <button
                                                    onClick={() => deleteResource(resource.id)}
                                                    className="p-1 text-red-500 hover:text-red-700"
                                                    title="Delete resource"
                                                >
                                                    <Trash2 size={16} />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))
                            )}
                            {editing ? (
                                <div className="mt-4 flex justify-end">
                                    {canEdit && !isNew && (
                                        <button onClick={() => onDeleteModule(module.id)} className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 mr-2">
                                            Delete Module
                                        </button>
                                    )}
                                    <button onClick={handleCancel} className="px-3 py-1 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 mr-2">
                                        Cancel
                                    </button>
                                    <button onClick={updateModule} className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600">
                                        Save
                                    </button>
                                </div>
                            ) : (
                                canEdit && (
                                    <button
                                        onClick={toggleEditMode}
                                        className="mt-4 text-blue-500 hover:text-blue-700"
                                    >
                                        Edit Module
                                    </button>
                                )
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <AddResourceModal
                show={showAddResourceModal}
                onClose={() => setShowAddResourceModal(false)}
                moduleId={module.id}
                resourceType={resourceType}
                courseId={courseId}
                onResourceAdded={handleResourceAdded}
                setError={setError}
            />
        </div>
    );
};

export default Module;