import React, { useState, useEffect } from 'react';
import { X, ArrowRight } from 'lucide-react';
import MarkdownRenderer from './../MarkdownRenderer';
import api from '../../api';

const Quiz = ({ onClose, questions, courseId, resourceId }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState('');
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [misunderstoodConcepts, setMisunderstoodConcepts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!questions || questions.length === 0) {
      setError('No quiz questions available.');
    } else {
      setError(null);
    }
  }, [questions]);

  const handleSubmitAnswer = async () => {
    if (!userAnswer.trim()) {
      alert('Please enter an answer before submitting.');
      return;
    }

    try {
      setSubmitting(true);
      const currentQuestion = questions[currentQuestionIndex];
      const response = await api.post(`/courses/${courseId}/quiz/grade`, {
        resourceId,
        question: currentQuestion.question,
        userAnswer,
        exampleAnswer: currentQuestion.exampleAnswer
      });
      setFeedback(response.data.feedback);
      
      if (response.data.misunderstoodConcepts) {
        setMisunderstoodConcepts(prev => [...new Set([...prev, ...response.data.misunderstoodConcepts])]);
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
      setFeedback('Error grading answer. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setUserAnswer('');
      setFeedback('');
    } else {
      setQuizCompleted(true);
    }
  };

  if (error) {
    return (
      <div className="fixed inset-0 bg-white z-50 flex flex-col items-center justify-center p-4">
        <h2 className="text-2xl font-bold mb-4">Quiz Error</h2>
        <p className="text-red-500 mb-4">{error}</p>
        <button onClick={onClose} className="bg-blue-500 text-white px-4 py-2 rounded">
          Close Quiz
        </button>
      </div>
    );
  }

  if (quizCompleted) {
    return (
      <div className="fixed inset-0 bg-white z-50 flex flex-col items-center justify-center p-4">
        <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
        {misunderstoodConcepts.length > 0 && (
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Concepts to review:</h3>
            <ul className="list-disc pl-5">
              {misunderstoodConcepts.map((concept, index) => (
                <li key={index}>{concept}</li>
              ))}
            </ul>
          </div>
        )}
        <button onClick={onClose} className="bg-blue-500 text-white px-4 py-2 rounded">
          Close Quiz
        </button>
      </div>
    );
  }

  if (!questions || questions.length === 0) {
    return null; // Render nothing if there are no questions
  }

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col p-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Quiz</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
      </div>

      <div className="flex-grow overflow-y-auto">
        <MarkdownRenderer content={questions[currentQuestionIndex].question} />
        <textarea
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          className="w-full h-32 p-2 border rounded mb-4"
          placeholder="Type your answer here..."
          disabled={!!feedback}
        />
        {!feedback ? (
          <button
            onClick={handleSubmitAnswer}
            disabled={submitting}
            className="bg-green-500 text-white px-4 py-2 rounded mr-2 disabled:bg-gray-400"
          >
            {submitting ? 'Submitting...' : 'Submit Answer'}
          </button>
        ) : (
          <div>
            <div className="mb-4 p-4 bg-blue-100 border-l-4 border-blue-500 rounded">
              <MarkdownRenderer content={feedback} />
            </div>
            <button
              onClick={handleNextQuestion}
              className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
            >
              {currentQuestionIndex < questions.length - 1 ? 'Next Question' : 'Finish Quiz'}
              <ArrowRight size={20} className="ml-2" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Quiz;