import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Camera, Menu, Globe, Users, DollarSign } from 'lucide-react';

const LandingPage = () => {
    const { loginWithRedirect } = useAuth0();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogin = () => {
        loginWithRedirect({
            redirectUri: window.location.origin + '/dashboard/my-courses',
        });
    };

    const handleSignUp = () => {
        loginWithRedirect({
            authorizationParams: { screen_hint: "signup", },
            redirectUri: window.location.origin + '/dashboard/my-courses',
        });
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const bubbles = [
        { text: "Interactive Learning", color: "bg-blue-400" },
        { text: "Instant Feedback", color: "bg-green-400" },
        { text: "Ask Any Question", color: "bg-purple-400" },
        { text: "Personalized Paths", color: "bg-yellow-400" },
        { text: "AI-Powered Tutoring", color: "bg-red-400" },
        { text: "Learn Anytime, Anywhere", color: "bg-indigo-400" },
        { text: "Adaptive Quizzes", color: "bg-pink-400" },
        { text: "Community Collaboration", color: "bg-teal-400" },
        { text: "Track Your Progress", color: "bg-orange-400" },
    ];

    return (
        <div className="min-h-screen bg-gray-100">
            <header className="fixed top-0 left-0 right-0 bg-gray-100 z-10">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <svg className="w-32 md:w-48" viewBox="0 0 193 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M160.281 26.752V8.36084H163.382V10.7132C163.489 11.034 163.809 10.8201 164.023 10.6063C164.192 10.4372 165.093 9.75087 165.841 9.32317C166.589 8.89547 167.766 8.36084 169.797 8.36084C176.042 8.36084 178.03 13.3507 178.244 15.8456V26.752H175.037V16.4872C174.823 14.8833 173.411 11.6755 169.476 11.6755C165.542 11.6755 163.845 14.8833 163.489 16.4872V26.752H160.281Z" fill="#282A31"></path>
                        <path d="M175.037 26.752V14.2417C175.206 14.0727 177.068 11.4617 178.993 10.0716C180.917 8.68162 182.521 8.36084 184.553 8.36084C190.797 8.36084 192.786 13.3507 193 15.8456V26.752H189.792V16.4872C189.578 14.8833 188.167 11.6755 184.232 11.6755C180.297 11.6755 178.601 14.8833 178.244 16.4872V26.752H175.037Z" fill="#282A31"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M147.664 27.2866C152.979 27.2866 157.287 22.9781 157.287 17.6633C157.287 12.3485 152.979 8.04004 147.664 8.04004C142.349 8.04004 138.04 12.3485 138.04 17.6633C138.04 22.9781 142.349 27.2866 147.664 27.2866ZM147.769 23.9028C151.157 23.9028 153.903 21.1568 153.903 17.7693C153.903 14.3819 151.157 11.6358 147.769 11.6358C144.382 11.6358 141.636 14.3819 141.636 17.7693C141.636 21.1568 144.382 23.9028 147.769 23.9028Z" fill="#282A31"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M126.706 27.2866C132.021 27.2866 136.33 22.9781 136.33 17.6633C136.33 12.3485 132.021 8.04004 126.706 8.04004C121.392 8.04004 117.083 12.3485 117.083 17.6633C117.083 22.9781 121.392 27.2866 126.706 27.2866ZM126.812 23.9028C130.2 23.9028 132.946 21.1568 132.946 17.7693C132.946 14.3819 130.2 11.6358 126.812 11.6358C123.425 11.6358 120.679 14.3819 120.679 17.7693C120.679 21.1568 123.425 23.9028 126.812 23.9028Z" fill="#282A31"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M105.749 27.2866C111.064 27.2866 115.372 22.9781 115.372 17.6633C115.372 12.3485 111.064 8.04004 105.749 8.04004C100.434 8.04004 96.1258 12.3485 96.1258 17.6633C96.1258 22.9781 100.434 27.2866 105.749 27.2866ZM105.855 23.9028C109.242 23.9028 111.988 21.1568 111.988 17.7693C111.988 14.3819 109.242 11.6358 105.855 11.6358C102.467 11.6358 99.7212 14.3819 99.7212 17.7693C99.7212 21.1568 102.467 23.9028 105.855 23.9028Z" fill="#282A31"></path>
                        <path d="M96.1257 3.1214V17.5563L99.975 11.7823V0.769043L96.1257 3.1214Z" fill="#282A31"></path>
                        <path d="M89.2825 10.0716V26.752H93.0249V8.36084L89.2825 10.0716Z" fill="#282A31"></path>
                        <circle cx="91.2072" cy="3.44218" r="2.5662" fill="#282A31"></circle>
                        <path d="M67.6837 26.752V8.36084H70.7845V10.7132C70.8914 11.034 71.2122 10.8201 71.426 10.6063C71.5951 10.4372 72.4953 9.75087 73.2438 9.32317C73.9922 8.89547 75.1684 8.36084 77.2 8.36084C83.4444 8.36084 85.4332 13.3507 85.6471 15.8456V26.752H82.4393V16.4872C82.2255 14.8833 80.8141 11.6755 76.8792 11.6755C72.9444 11.6755 71.2478 14.8833 70.8914 16.4872V26.752H67.6837Z" fill="#282A31"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M55.6011 26.4312C60.6206 26.4312 64.6897 22.3621 64.6897 17.3425C64.6897 12.323 60.6206 8.25391 55.6011 8.25391C50.5816 8.25391 46.5125 12.323 46.5125 17.3425C46.5125 22.3621 50.5816 26.4312 55.6011 26.4312ZM55.708 22.9027C58.7788 22.9027 61.2681 20.4134 61.2681 17.3426C61.2681 14.2719 58.7788 11.7825 55.708 11.7825C52.6372 11.7825 50.1479 14.2719 50.1479 17.3426C50.1479 20.4134 52.6372 22.9027 55.708 22.9027Z" fill="#282A31"></path>
                        <path d="M60.6266 27.8215C60.1989 26.795 59.0227 26.1107 58.4881 25.8968L61.375 24.0791C61.8384 24.3999 62.8292 25.127 63.0858 25.4691C63.4066 25.8968 64.4759 26.4315 64.4759 29.5323C64.4759 32.6331 62.3374 34.8785 61.482 35.5201C60.6266 36.1617 58.595 37.2309 55.9218 37.2309C53.2487 37.2309 51.3241 36.1617 50.2548 35.3062C49.1856 34.4508 47.7955 32.9539 47.7955 30.8154C47.7955 28.6769 48.0094 28.0353 48.4371 27.7146C48.8648 27.3938 48.9717 27.2869 50.041 27.2869C50.8964 27.2869 51.6092 27.7146 51.8587 27.9284C52.0369 27.9997 52.2864 28.2706 51.8587 28.7838C51.431 29.2971 51.1815 30.2095 51.1102 30.6015C51.1102 32.9111 54.1754 33.5598 55.708 33.5954C56.4921 33.7024 58.3597 33.6168 59.5573 32.4193C61.0543 30.9223 61.1612 29.1046 60.6266 27.8215Z" fill="#282A31"></path>
                        <path d="M61.9097 9.75106C61.4301 9.88809 61.5176 10.4639 61.6958 10.7134C60.484 10.0362 58.1887 8.72458 58.7019 8.89566C59.3435 9.10951 59.6643 8.68181 59.8781 8.14718C60.092 7.61256 60.4127 6.65023 60.8404 6.00868C61.2681 5.36713 62.2305 4.61865 63.2997 4.61865C64.369 4.61865 65.0105 5.47405 65.0105 6.22253C65.0105 6.97101 64.5828 7.82641 63.9413 8.57489C63.2997 9.32336 62.6582 9.53721 61.9097 9.75106Z" fill="#282A31"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M34.9645 27.2866C40.2793 27.2866 44.5878 22.9781 44.5878 17.6633C44.5878 12.3485 40.2793 8.04004 34.9645 8.04004C29.6497 8.04004 25.3412 12.3485 25.3412 17.6633C25.3412 22.9781 29.6497 27.2866 34.9645 27.2866ZM35.0703 23.9028C38.4577 23.9028 41.2038 21.1568 41.2038 17.7693C41.2038 14.3819 38.4577 11.6358 35.0703 11.6358C31.6828 11.6358 28.9367 14.3819 28.9367 17.7693C28.9367 21.1568 31.6828 23.9028 35.0703 23.9028Z" fill="#282A31"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.0446 21.095C21.7843 24.868 17.6561 27.3935 12.938 27.3935C5.79252 27.3935 0 21.601 0 14.4555C0 7.3101 5.79252 1.51758 12.938 1.51758C17.5283 1.51758 21.5602 3.90812 23.8569 7.51217L20.7606 9.65007C19.1554 7.0782 16.3 5.36702 13.0449 5.36702C8.02537 5.36702 3.95625 9.43615 3.95625 14.4557C3.95625 19.4752 8.02537 23.5443 13.0449 23.5443C16.3253 23.5443 19.1998 21.8064 20.7978 19.2011L24.0446 21.095Z" fill="#282A31"></path>
                    </svg>

                    <nav className="hidden md:flex space-x-8">
                        <a href="#" className="text-gray-700 hover:text-blue-600">CLASSES</a>
                        <a href="#" className="text-gray-700 hover:text-blue-600">LEARN</a>
                        <a href="#" className="text-gray-700 hover:text-blue-600">SERVICE</a>
                        <a href="#" className="text-gray-700 hover:text-blue-600">COMPANY</a>
                    </nav>
                    <div className="flex items-right space-x-4">
                        <button className="py-2 px-4 text-gray-600 hover:text-purple-700" onClick={handleLogin}>LOG IN</button>
                        <button className="py-2 px-4 bg-gray-800 text-white rounded-full hover:bg-purple-700" onClick={handleSignUp}>SIGN UP</button>

                        <div className="flex items-center md:hidden">
                            <button onClick={toggleMenu} className="text-gray-500 hover:text-gray-600">
                                <Menu size={24} />
                            </button>
                        </div>
                    </div>


                </div>

                {isMenuOpen && (
                    <div className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                            <a href="#" className="block px-3 py-2 text-gray-700 hover:text-blue-600">CLASSES</a>
                            <a href="#" className="block px-3 py-2 text-gray-700 hover:text-blue-600">LEARN</a>
                            <a href="#" className="block px-3 py-2 text-gray-700 hover:text-blue-600">SERVICE</a>
                            <a href="#" className="block px-3 py-2 text-gray-700 hover:text-blue-600">COMPANY</a>
                        </div>
                    </div>
                )}
            </header>

            <main className="pt-20">
                <section className="hero relative min-h-screen flex flex-col justify-center items-center px-4">
                    <div className="hero-logo w-full max-w-xl">
                        <svg viewBox="0 0 903 550" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="658" cy="29" r="29" fill="#797EB5"></circle>
                            <path d="M297.5 116C285.5 116 275.846 120 266.5 133.5C255.423 149.5 255.1 161.3 259.5 172.5C265 186.5 285 197 309.5 202C326.135 205.395 360 214.5 370.5 197C380.996 179.505 357 148.953 338.5 134.5C322 121.61 313 116 297.5 116Z" fill="#EC7579"></path>
                            <path d="M369.5 49.5C359.5 49.5 352 52 340.5 63C328.5 74.4782 323.086 81.9311 326 99C329.5 119.5 359.5 143 369.5 150C379.5 157 396 161 404.5 156C413 151 416 136.5 417 123C418 109.5 414 88.0001 404.5 72.0001C395 56.0001 379.5 49.5 369.5 49.5Z" fill="#F7C848"></path>
                            <path d="M520.5 57.5001C533.5 49.5003 548.5 50.5001 564 66.0003C578 78.0005 578 86.2001 578 95.0001C578 106 574.489 117.489 563.5 128C552 139 531 158.525 514.5 161C498 163.475 483.5 159 484.5 126C485.5 93.0003 506.986 65.8161 520.5 57.5001Z" fill="#797DB5"></path>
                            <path d="M646.877 165.394C647.352 155.405 645.665 148.317 634.771 135.785C623.876 123.253 611.688 122.9 594.5 125C573.857 127.521 549.468 151.344 542 161C534.533 170.656 524.251 185.5 531.5 199.284C536.09 208.013 557 209.346 571.201 209.346C584.738 209.346 605.551 206.415 622.738 199.284C641.5 191.5 646.401 175.382 646.877 165.394Z" fill="#269DCF"></path>
                            <path d="M609.5 432.5C619 427.5 626.806 422.697 633.5 407.5C638.566 396 637.5 383.861 628.938 370.259C617.859 352.659 585.914 340.748 573.996 338.108C562.079 335.468 546.5 334.5 538 342C528.5 350.382 533.948 376.153 540 389C548.5 407.042 560.312 423.572 574 432.5C585.5 440 600.651 437.158 609.5 432.5Z" fill="#787CB6"></path>
                            <path d="M493.079 478.402C503.507 480.951 512.537 482.526 527.619 475.579C539.033 470.322 544.432 462.777 547.5 447C551 429 541 407.5 531 391C524.673 380.561 510.825 363.258 499.489 363.244C486.82 363.228 473.825 386.139 469.846 399.772C464.258 418.917 462.152 439.124 466.49 454.88C470.134 468.117 483.365 476.027 493.079 478.402Z" fill="#787CB6"></path>
                            <path d="M382.5 478.5C392.5 482.663 401.808 486.432 417.5 481C430.5 476.5 438.538 469.616 444 454.5C450.232 437.255 443.156 414.656 435.818 396.812C431.175 385.523 421 366.5 408.5 365.5C392.5 364.22 380.532 383.956 374.5 396.812C366.029 414.868 358.642 435.263 360.5 451.5C362.061 465.14 373.268 474.657 382.5 478.5Z" fill="#F6C747"></path>
                            <path d="M271.5 409.5C275 421 285.5 432.944 296 438C308.5 444.019 318.825 445.946 332.5 437.5C349.5 427 361.132 407.332 368.5 389.5C373.162 378.219 378 358 370.742 347.202C361.789 333.88 335.5 339 313 350.001C313 350.001 305.558 363.82 298 370.001C290.019 376.527 274 381.001 274 381.001C269 389.5 268.588 399.933 271.5 409.5Z" fill="#F7AC55"></path>
                            <path d="M750 320V234H764.5V245C765 246.5 766.5 245.5 767.5 244.5C768.291 243.709 772.5 240.5 776 238.5C779.5 236.5 785 234 794.5 234C823.7 234 833 257.333 834 269V320H819V272C818 264.5 811.4 249.5 793 249.5C774.6 249.5 766.667 264.5 765 272V320H750Z" fill="#282A31"></path>
                            <path d="M819 320V261.5C819.791 260.709 828.5 248.5 837.5 242C846.5 235.5 854 234 863.5 234C892.7 234 902 257.333 903 269V320H888V272C887 264.5 880.4 249.5 862 249.5C843.6 249.5 835.667 264.5 834 272V320H819Z" fill="#282A31"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M691 322.5C715.853 322.5 736 302.353 736 277.5C736 252.647 715.853 232.5 691 232.5C666.147 232.5 646 252.647 646 277.5C646 302.353 666.147 322.5 691 322.5ZM691.495 306.676C707.335 306.676 720.176 293.835 720.176 277.995C720.176 262.154 707.335 249.313 691.495 249.313C675.654 249.313 662.813 262.154 662.813 277.995C662.813 293.835 675.654 306.676 691.495 306.676Z" fill="#282A31"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M593 322.5C617.853 322.5 638 302.353 638 277.5C638 252.647 617.853 232.5 593 232.5C568.147 232.5 548 252.647 548 277.5C548 302.353 568.147 322.5 593 322.5ZM593.495 306.676C609.335 306.676 622.176 293.835 622.176 277.995C622.176 262.154 609.335 249.313 593.495 249.313C577.654 249.313 564.813 262.154 564.813 277.995C564.813 293.835 577.654 306.676 593.495 306.676Z" fill="#282A31"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M495 322.5C519.853 322.5 540 302.353 540 277.5C540 252.647 519.853 232.5 495 232.5C470.147 232.5 450 252.647 450 277.5C450 302.353 470.147 322.5 495 322.5ZM495.495 306.676C511.335 306.676 524.176 293.835 524.176 277.995C524.176 262.154 511.335 249.313 495.495 249.313C479.654 249.313 466.813 262.154 466.813 277.995C466.813 293.835 479.654 306.676 495.495 306.676Z" fill="#282A31"></path>
                            <path d="M450 209.5V277L468 250V198.5L450 209.5Z" fill="#282A31"></path>
                            <path d="M418 242V320H435.5V234L418 242Z" fill="#282A31"></path>
                            <circle cx="427" cy="211" r="12" fill="#282A31"></circle>
                            <path d="M317 320V234H331.5V245C332 246.5 333.5 245.5 334.5 244.5C335.291 243.709 339.5 240.5 343 238.5C346.5 236.5 352 234 361.5 234C390.7 234 400 257.333 401 269V320H386V272C385 264.5 378.4 249.5 360 249.5C341.6 249.5 333.667 264.5 332 272V320H317Z" fill="#282A31"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M260.5 318.5C283.972 318.5 303 299.472 303 276C303 252.528 283.972 233.5 260.5 233.5C237.028 233.5 218 252.528 218 276C218 299.472 237.028 318.5 260.5 318.5ZM261 302C275.359 302 287 290.359 287 276C287 261.641 275.359 250 261 250C246.641 250 235 261.641 235 276C235 290.359 246.641 302 261 302Z" fill="#282A31"></path>
                            <path d="M284 325C282 320.2 276.5 317 274 316L287.5 307.5C289.667 309 294.3 312.4 295.5 314C297 316 302 318.5 302 333C302 347.5 292 358 288 361C284 364 274.5 369 262 369C249.5 369 240.5 364 235.5 360C230.5 356 224 349 224 339C224 329 225 326 227 324.5C229 323 229.5 322.5 234.5 322.5C238.5 322.5 241.833 324.5 243 325.5C243.833 325.833 245 327.1 243 329.5C241 331.9 239.833 336.167 239.5 338C239.5 348.8 253.833 351.833 261 352C264.667 352.5 273.4 352.1 279 346.5C286 339.5 286.5 331 284 325Z" fill="#282A31"></path>
                            <path d="M290 240.5C287.757 241.141 288.167 243.833 289 245C283.333 241.833 272.6 235.7 275 236.5C278 237.5 279.5 235.5 280.5 233C281.5 230.5 283 226 285 223C287 220 291.5 216.5 296.5 216.5C301.5 216.5 304.5 220.5 304.5 224C304.5 227.5 302.5 231.5 299.5 235C296.5 238.5 293.5 239.5 290 240.5Z" fill="#282A31"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M164 322.5C188.853 322.5 209 302.353 209 277.5C209 252.647 188.853 232.5 164 232.5C139.147 232.5 119 252.647 119 277.5C119 302.353 139.147 322.5 164 322.5ZM164.495 306.676C180.335 306.676 193.176 293.835 193.176 277.995C193.176 262.154 180.335 249.313 164.495 249.313C148.654 249.313 135.813 262.154 135.813 277.995C135.813 293.835 148.654 306.676 164.495 306.676Z" fill="#282A31"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M112.937 293.547C102.368 311.19 83.0631 323 61 323C27.5868 323 0.5 295.913 0.5 262.5C0.5 229.087 27.5868 202 61 202C82.4649 202 101.319 213.178 112.058 230.031L97.5798 240.028C90.0735 228.002 76.7212 220 61.5 220C38.0279 220 19 239.028 19 262.5C19 285.972 38.0279 305 61.5 305C76.8398 305 90.2814 296.873 97.7542 284.69L112.937 293.547Z" fill="#282A31"></path>
                        </svg>
                    </div>
                    <div className="hero-content text-center mt-8">
                        <h4 className="text-2xl md:text-3xl font-bold mb-6">Unlock your mind's potential</h4>
                        <button className="px-6 py-3 bg-gray-800 text-white rounded-full text-lg hover:bg-purple-700" onClick={handleSignUp}>START LEARNING</button>
                    </div>
                </section>

                {/* New section: Revolutionary Learning Approach */}
                <section className="py-16 md:py-48 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">A Revolutionary Approach to Learning</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                            <div>
                                <p className="text-lg mb-6">Cogniboom introduces a groundbreaking way to learn that adapts to you. Our platform combines the latest in AI technology with proven learning techniques to create a personalized experience that helps you learn faster and retain more.</p>
                                <p className="text-lg mb-6">Unlike traditional methods that rely on passive reading and multiple-choice questions, Cogniboom engages you in active learning through interactive conversations, open-ended questions, and real-time feedback.</p>
                            </div>
                            <div className="bg-purple-100 rounded-lg p-6">
                                <h3 className="text-xl font-semibold mb-4">How It Works:</h3>
                                <ul className="space-y-2">
                                    <li className="flex items-center"><span className="text-purple-600 mr-2">✓</span> Upload your learning materials or choose from our library of courses</li>
                                    <li className="flex items-center"><span className="text-purple-600 mr-2">✓</span> Engage in dynamic, AI-powered conversations about the content</li>
                                    <li className="flex items-center"><span className="text-purple-600 mr-2">✓</span> Test your knowledge with adaptive quizzes and exercises</li>
                                    <li className="flex items-center"><span className="text-purple-600 mr-2">✓</span> Receive personalized feedback and explanations</li>
                                    <li className="flex items-center"><span className="text-purple-600 mr-2">✓</span> Track your progress and focus on areas that need improvement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-bubbles py-16 md:py-24 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap justify-center gap-4">
                            {bubbles.map((bubble, index) => (
                                <div 
                                    key={index} 
                                    className={`${bubble.color} text-white px-4 py-2 rounded-full text-lg font-semibold shadow-lg transform transition-all duration-300 hover:scale-110`}
                                >
                                    {bubble.text}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* A Private Tutor for Everyone */}
                <section className="explainer py-16 md:py-48 bg-gray-100">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12">A Private Tutor for Everyone</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
                            {[
                                { title: "Discover & Create", color: "bg-red-500", content: "Explore a vast library of courses or create your own on any topic. Ideal for students enhancing their studies, educators sharing knowledge, and lifelong learners pursuing passions." },
                                { title: "Upload & Connect", color: "bg-purple-600 text-white", content: "Upload textbooks, notes, or documents. Link to videos, articles, or any online content. Our system processes and organizes it all into a cohesive learning experience." },
                                { title: "Learn", color: "bg-yellow-400", content: "Engage with adaptive learning tools that evolve with you. Experience interactive quizzes, summaries, and discussions. Get instant feedback, ask questions, and receive explanations on any topic." },
                                { title: "Boom!", color: "bg-blue-500 text-white", content: "Boost your understanding, ace your exams, and reach your academic goals with confidence. Turn passive reading into active learning, track your progress, and watch your grades improve." }
                            ].map((item, index) => (
                                <div key={index} className={`${item.color} rounded-lg p-6`}>
                                    <h4 className="text-xl font-semibold mb-4">{item.title}</h4>
                                    <p>{item.content}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* New Our Vision Section */}
                <section className="our-vision py-16 md:py-24">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Our Vision</h2>
                        
                        <h2 className="text-2xl md:text-2xl  text-center mb-28">Provide High Quality Education About Anything to Everyone</h2>
                        
                        <h2 className="text-1xl md:text-1xl  text-center mb-8">We hope to achieve this goal by:</h2>

                        
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                            <div className="text-center p-6 bg-gray-50 rounded-lg shadow-sm">
                                <Globe size={48} className="mx-auto mb-4 text-blue-500" />
                                <h3 className="text-xl font-semibold mb-2">Global Access</h3>
                                <p className="text-gray-600">Breaking down geographical barriers to bring quality education to every corner of the world.</p>
                            </div>
                            <div className="text-center p-6 bg-gray-50 rounded-lg shadow-sm">
                                <Users size={48} className="mx-auto mb-4 text-green-500" />
                                <h3 className="text-xl font-semibold mb-2">Inclusive Learning</h3>
                                <p className="text-gray-600">Creating a platform that adapts to diverse learning styles and needs, ensuring no one is left behind.</p>
                            </div>
                            <div className="text-center p-6 bg-gray-50 rounded-lg shadow-sm">
                                <DollarSign size={48} className="mx-auto mb-4 text-purple-500" />
                                <h3 className="text-xl font-semibold mb-2">Affordable Excellence</h3>
                                <p className="text-gray-600">Delivering top-tier educational experiences at a minuscule fraction of traditional costs.</p>
                            </div>
                        </div>
                        <div className="max-w-3xl mx-auto ">
                            <p className="text-lg md:text-xl mb-6 text-gray-700">At Cogniboom, we believe that high-quality education should be accessible to everyone, regardless of their background or financial situation. Our mission is to harness the power of technology to provide world-class learning experiences at an incredibly low cost.</p>
                            <p className="text-lg md:text-xl mb-8 text-gray-700">By combining artificial intelligence, optimal learning techniques, and a community-driven library of resources and courses, we hope to create a platform that delivers personalized, engaging, and effective education to millions of learners worldwide.</p>
                        </div>
                    </div>
                </section>
                <section className="cta py-16 md:py-48 bg-gray-100">
                    <div className="container mx-auto px-4 text-center">
                        <h2 className="text-3xl md:text-4xl font-bold mb-4">Ready to ignite your learning journey?</h2>
                        <p className="text-lg md:text-xl text-gray-600 mb-8">Join today for free and start learning anything and everything</p>
                        <button className="px-6 md:px-8 py-3 md:py-4 bg-purple-600 text-white rounded-full text-lg md:text-xl hover:bg-purple-700">
                            Get Started
                        </button>
                    </div>
                </section>
            </main>

            <footer className="bg-gray-800 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
                        <div>
                            <h4 className="text-2xl font-bold mb-4">Cogniboom</h4>
                            {/* Add logo or additional content here */}
                        </div>
                        {['Company', 'Resources', 'Legal'].map((category, index) => (
                            <div key={index}>
                                <h4 className="text-lg font-semibold mb-4">{category}</h4>
                                <ul className="space-y-2">
                                    {['Link 1', 'Link 2', 'Link 3', 'Link 4'].map((link, i) => (
                                        <li key={i}><a href="#" className="hover:text-purple-400">{link}</a></li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="border-t border-gray-700 pt-8 flex flex-col md:flex-row justify-between items-center">
                        <p>&copy; 2024 Cogniboom. All rights reserved.</p>
                        <div className="flex space-x-4 mt-4 md:mt-0">
                            {['facebook', 'twitter', 'instagram', 'linkedin'].map((social, index) => (
                                <a key={index} href="#" className="text-gray-400 hover:text-white">
                                    <Camera size={24} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;