import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownComponents = {
  h1: ({ node, ...props }) => <h1 className="text-4xl font-bold mt-6 mb-6" {...props} />,
  h2: ({ node, ...props }) => <h2 className="text-3xl font-semibold mt-6 mb-3" {...props} />,
  h3: ({ node, ...props }) => <h3 className="text-2xl font-medium mt-4 mb-2" {...props} />,
  h4: ({ node, ...props }) => <h4 className="text-xl font-medium mt-3 mb-2" {...props} />,
  h5: ({ node, ...props }) => <h5 className="text-lg font-medium mt-2 mb-1" {...props} />,
  h6: ({ node, ...props }) => <h6 className="text-base font-medium mt-2 mb-1" {...props} />,
  p: ({ node, ...props }) => <p className="mb-4 leading-relaxed" {...props} />,
  ul: ({ node, ...props }) => <ul className="list-disc pl-6 mb-4" {...props} />,
  ol: ({ node, ...props }) => <ol className="list-decimal pl-6 mb-4" {...props} />,
  li: ({ node, ...props }) => <li className="mb-2" {...props} />,
  a: ({ node, ...props }) => <a className="text-blue-600 hover:underline" {...props} />,
  blockquote: ({ node, ...props }) => <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4" {...props} />,
  code: ({ node, inline, ...props }) => 
    inline ? (
      <code className="bg-gray-100 rounded px-1 py-0.5" {...props} />
    ) : (
      <code className="block bg-gray-100 rounded p-2 my-2 whitespace-pre-wrap" {...props} />
    ),
  pre: ({ node, ...props }) => <pre className="bg-gray-100 rounded p-2 my-2 overflow-auto" {...props} />,
  table: ({ node, ...props }) => <table className="table-auto border-collapse my-4" {...props} />,
  th: ({ node, ...props }) => <th className="border border-gray-300 px-4 py-2 bg-gray-100" {...props} />,
  td: ({ node, ...props }) => <td className="border border-gray-300 px-4 py-2" {...props} />,
  img: ({ node, ...props }) => <img className="max-w-full h-auto my-4" {...props} />,
  hr: ({ node, ...props }) => <hr className="my-8 border-t border-gray-300" {...props} />,
};

const MarkdownRenderer = ({ content }) => {
  return (
    <div className="prose max-w-none">
      <ReactMarkdown components={MarkdownComponents}>
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer;