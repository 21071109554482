import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Search } from 'lucide-react';
import MarkdownRenderer from './../MarkdownRenderer';
import api from '../../api';
import Quiz from './Quiz';

const MAX_CHUNKS = 60;

const Learn = () => {
    const { courseId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [content, setContent] = useState('');
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [userQuery, setUserQuery] = useState('');
    const [isLargeResource, setIsLargeResource] = useState(false);
    const [resourceProvided, setResourceProvided] = useState(false);
    const [showQuiz, setShowQuiz] = useState(false);
    const [generatingQuestions, setGeneratingQuestions] = useState(false);

    const moduleId = queryParams.get('module');
    const resourceId = queryParams.get('resource');
    const pageStart = queryParams.get('pageStart');
    const pageEnd = queryParams.get('pageEnd');

    const fetchContent = useCallback(async (query = '') => {
        setLoading(true);
        setError('');
        try {
            const params = {
                page: currentPage,
                query: query || (resourceId ? `Tell me about this resource` : ''),
                filters: {
                    moduleId,
                    resourceId,
                    pageRange: pageStart && pageEnd ? { start: parseInt(pageStart), end: parseInt(pageEnd) } : null
                }
            };

            if (resourceId) {
                const resourceResponse = await api.get(`/courses/${courseId}/resources/${resourceId}/metadata`);
                const resourceMetadata = resourceResponse.data;
                if (resourceMetadata.chunkCount > MAX_CHUNKS) {
                    setIsLargeResource(true);
                    if (!query) {
                        setContent('');
                        setLoading(false);
                        return;
                    }
                }
            }

            const response = await api.post(`/courses/${courseId}/learn`, params);
            setContent(response.data.content);
            setQuizQuestions(response.data.quizQuestions || []);
            setTotalPages(response.data.totalPages);
            setIsLargeResource(false);
        } catch (err) {
            console.error('Error fetching content:', err);
            setError('Failed to fetch content: ' + (err.response?.data?.error || err.message));
        } finally {
            setLoading(false);
        }
    }, [courseId, currentPage, moduleId, resourceId, pageStart, pageEnd]);

    useEffect(() => {
        setResourceProvided(!!resourceId);
        if (moduleId || resourceId || pageStart || pageEnd) {
            fetchContent();
        }
    }, [fetchContent, moduleId, resourceId, pageStart, pageEnd]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchContent(userQuery);
    };

    const handleQuizClick = async () => {
        console.log(quizQuestions.length)
        if (quizQuestions.length === 0) {
            setGeneratingQuestions(true);
            try {
                const response = await api.post(`/courses/${courseId}/generate-questions`, {
                    content,
                    resourceId
                });
                setQuizQuestions(response.data.questions);
            } catch (err) {
                console.error('Error generating questions:', err);
                setError('Failed to generate quiz questions: ' + (err.response?.data?.error || err.message));
            } finally {
                setGeneratingQuestions(false);
            }
        }
        setShowQuiz(true);
    };

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorMessage error={error} />;

    return (
        <div className="max-w-4xl mx-auto mt-4 p-6 rounded-lg">
            {(!resourceProvided || isLargeResource) && (
                <form onSubmit={handleSubmit} className="my-6">
                    <div className="flex items-center">
                        <input
                            type="text"
                            value={userQuery}
                            onChange={(e) => setUserQuery(e.target.value)}
                            placeholder={isLargeResource ? "Enter a specific topic or concept to learn about" : "What do you want to learn about?"}
                            className="flex-grow p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <Search size={20} className="mr-2" />
                            Learn
                        </button>
                    </div>
                </form>
            )}
            {content && (
                <>
                    <div className="bg-gray-50 p-6 rounded-lg shadow-inner mb-6">
                        <MarkdownRenderer content={content} />
                    </div>
                    <button
                        onClick={handleQuizClick}
                        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 ease-in-out"
                        disabled={generatingQuestions}
                    >
                        {generatingQuestions ? 'Generating Questions...' : 'Check Understanding'}
                    </button>
                </>
            )}
            {showQuiz && quizQuestions.length > 0 && (
                <Quiz
                    onClose={() => setShowQuiz(false)}
                    questions={quizQuestions}
                    courseId={courseId}
                    resourceId={resourceId}
                />
            )}
        </div>
    );
};

const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
    </div>
);

const ErrorMessage = ({ error }) => (
    <div className="max-w-4xl mx-auto p-6 bg-red-100 border-l-4 border-red-500 text-red-700">
        <p className="font-bold">Error</p>
        <p>{error}</p>
    </div>
);

export default Learn;