import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from './Navbar';
import { useUser } from '../../UserContext';
import Loading from '../util/Loading';
import { User, CreditCard } from 'lucide-react';
import { useApi } from '../../api';

const Profile = () => {
    const { user } = useAuth0();
    const { userProfile, loading, fetchUserProfile } = useUser();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('profile');
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [loadingSubscription, setLoadingSubscription] = useState(false);
    const api = useApi();

    useEffect(() => {
        if (userProfile?.isPaidUser) {
            fetchSubscriptionDetails();
        }
    }, [userProfile]);

    const fetchSubscriptionDetails = async () => {
        setLoadingSubscription(true);
        try {
            const response = await api.get('/subscription-details');
            setSubscriptionDetails(response.data);
        } catch (error) {
            console.error('Error fetching subscription details:', error);
        }
        setLoadingSubscription(false);
    };

    const handleCancelSubscription = async () => {
        try {
            await api.post('/cancel-subscription');
            alert('Subscription cancelled successfully');
            await fetchUserProfile();
            await fetchSubscriptionDetails();
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            alert('Failed to cancel subscription');
        }
    };

    if (loading || loadingSubscription) {
        return <Loading />;
    }

    const renderContent = () => {
        switch (activeTab) {
            case 'profile':
                return (
                    <div className="bg-white p-4 rounded-lg shadow-md">
                        <h2 className="text-xl font-bold mb-4">Profile Information</h2>
                        <div className="flex flex-col sm:flex-row items-center mb-4">
                            <img
                                src={user.picture || '/api/placeholder/100/100'}
                                alt="Profile"
                                className="w-20 h-20 rounded-full mb-2 sm:mb-0 sm:mr-4"
                            />
                            <div>
                                <p><strong>Name:</strong> {user.name}</p>
                                <p><strong>Email:</strong> {user.email}</p>
                            </div>
                        </div>
                        <p><strong>Subscription Status:</strong> {userProfile?.isPaidUser ? 'Premium' : 'Free'}</p>
                    </div>
                );
            case 'billing':
                return (
                    <div className="bg-white p-4 rounded-lg shadow-md">
                        <h2 className="text-xl font-bold mb-4">Billing Information</h2>
                        {userProfile?.isPaidUser && subscriptionDetails ? (
                            <>
                                <p><strong>Plan:</strong> {subscriptionDetails.planName}</p>
                                <p><strong>Status:</strong> {subscriptionDetails.status}</p>
                                <p><strong>Next Billing Date:</strong> {new Date(subscriptionDetails.nextBillingDate).toLocaleDateString()}</p>
                                <button
                                    onClick={handleCancelSubscription}
                                    className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 w-full sm:w-auto"
                                >
                                    Cancel Subscription
                                </button>
                            </>
                        ) : (
                            <p>You are currently on the Free plan. Upgrade to access premium features!</p>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <Navbar />
            <div className="max-w-6xl mx-auto mt-6 px-4">
                <h1 className="text-3xl font-bold mb-8">User Profile</h1>
                <div className="flex flex-col sm:flex-row">
                    {/* Sidebar */}
                    <div className="w-full sm:w-1/4 bg-white rounded-lg shadow-md p-4 mb-4 sm:mb-0 sm:mr-4">
                        <button
                            className={`flex items-center w-full p-2 rounded ${activeTab === 'profile' ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'}`}
                            onClick={() => setActiveTab('profile')}
                        >
                            <User className="mr-2" />
                            Profile
                        </button>
                        {userProfile?.isPaidUser && (
                            <button
                                className={`flex items-center w-full p-2 rounded mt-2 ${activeTab === 'billing' ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'}`}
                                onClick={() => setActiveTab('billing')}
                            >
                                <CreditCard className="mr-2" />
                                Billing
                            </button>
                        )}
                    </div>

                    {/* Content area */}
                    <div className="w-full sm:w-3/4">
                        {renderContent()}
                        {/* {!userProfile?.isPaidUser && activeTab === 'profile' && (
                            <button
                                onClick={() => navigate('/subscribe')}
                                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full sm:w-auto"
                            >
                                Upgrade to Premium
                            </button>
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;