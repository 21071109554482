// AddResourceModal.js

import React, { useState } from 'react';
import api from '../../api';

const AddResourceModal = ({ show, onClose, moduleId, resourceType, courseId, onResourceAdded, setError }) => {
    const [resourceTitle, setResourceTitle] = useState('');
    const [resourceContent, setResourceContent] = useState('');
    const [resourceFile, setResourceFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            let response;
            if (resourceType === 'text') {
                response = await api.post(`/courses/${courseId}/modules/${moduleId}/resources`, {
                    type: 'text',
                    title: resourceTitle,
                    content: resourceContent
                });
            } else if (resourceType === 'file') {
                const formData = new FormData();
                formData.append('type', 'file');
                formData.append('file', resourceFile);
                response = await api.post(`/courses/${courseId}/modules/${moduleId}/resources`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }

            // Fetch the updated module data
            const updatedModule = await api.get(`/courses/${courseId}/modules/${moduleId}`);

            // Call the onResourceAdded callback with the updated module data
            onResourceAdded(moduleId, updatedModule.data);

            onClose();
        } catch (err) {
            console.error('Error adding resource:', err);
            setError(err.response?.data?.message || 'Failed to add resource. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full">
                <h2 className="text-xl font-bold mb-4">Add {resourceType === 'text' ? 'Text' : 'File'} Resource</h2>
                <form onSubmit={handleSubmit}>
                    {resourceType === 'text' && (
                        <>
                            <input
                                type="text"
                                value={resourceTitle}
                                onChange={(e) => setResourceTitle(e.target.value)}
                                placeholder="Resource Title"
                                className="w-full p-2 mb-4 border rounded"
                                required
                            />
                            <textarea
                                value={resourceContent}
                                onChange={(e) => setResourceContent(e.target.value)}
                                placeholder="Content"
                                className="w-full p-2 mb-4 border rounded"
                                rows="4"
                                required
                            />
                        </>
                    )}
                    {resourceType === 'file' && (
                        <input
                            type="file"
                            accept=".pdf,.txt,.doc,.docx,.rtf"
                            onChange={(e) => setResourceFile(e.target.files[0])}
                            className="w-full p-2 mb-4 border rounded"
                            required
                        />
                    )}
                    <div className="flex justify-end">
                        <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 mr-2" disabled={isLoading}>Cancel</button>
                        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" disabled={isLoading}>
                            {isLoading ? 'Adding...' : 'Add Resource'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddResourceModal;