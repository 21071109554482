import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

api.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let getTokenFunc = null;

const getToken = async () => {
  if (!getTokenFunc) {
    console.error('getToken function not set');
    return null;
  }
  try {
    return await getTokenFunc();
  } catch (error) {
    console.error('Error getting access token', error);
    return null;
  }
};

export const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {
    getTokenFunc = () => getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid profile email'
    });
  }, [getAccessTokenSilently]);

  return api;
};

export default api;