import React, { useState } from 'react';
import api from '../api';

const Subscribe = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubscribe = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await api.post('/create-checkout-session');
      window.location.href = response.data.url;
    } catch (err) {
      setError('Failed to start subscription process: ' + (err.response?.data?.message || err.message));
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-4">Subscribe to Premium</h2>
      <p className="mb-4">Get access to create unlimited courses and more!</p>
      <button
        onClick={handleSubscribe}
        disabled={isLoading}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      >
        {isLoading ? 'Processing...' : 'Subscribe Now'}
      </button>
      {error && <p className="text-red-500 mt-4">{error}</p>}
    </div>
  );
};

export default Subscribe;