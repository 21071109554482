import React from 'react';
import Navbar from './Navbar';

const BrowseCourses = () => {
    const templateCourses = [
        { id: 1, name: 'Introduction to React', description: 'Learn the basics of React' },
        { id: 2, name: 'Advanced JavaScript', description: 'Master advanced JavaScript concepts' },
        { id: 3, name: 'Python for Beginners', description: 'Start your Python journey' },
    ];

    return (
        <div className="min-h-screen bg-gray-100">
            <Navbar />

            <div className="max-w-6xl mx-auto mt-5 px-4">
                <h1 className="text-3xl font-bold mb-8">Browse Courses</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {templateCourses.map(course => (
                        <div key={course.id} className="bg-white p-4 rounded shadow">
                            <h2 className="text-xl font-semibold">{course.name}</h2>
                            <p className="text-gray-600">{course.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BrowseCourses;