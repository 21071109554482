import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Plus } from 'lucide-react';
import api from '../../api';
import Module from './Module';
import AddResourceModal from './AddResourceModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { fetchCourseData } from './dashboardUtils';
import Loading from '../util/Loading';

const Dashboard = () => {
    const { courseId } = useParams();
    const [course, setCourse] = useState(null);
    const [modules, setModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showResourceModal, setShowResourceModal] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [currentModuleId, setCurrentModuleId] = useState(null);
    const [resourceType, setResourceType] = useState(null);
    const [moduleToDelete, setModuleToDelete] = useState(null);
    const [userPermission, setUserPermission] = useState(null);

    useEffect(() => {
        fetchCourseData(courseId, setCourse, setModules, setLoading, setError, setUserPermission);
    }, [courseId]);

    const canEdit = userPermission === 'owner' || userPermission === 'write';

    const handleAddResource = (moduleId, type) => {
        if (!canEdit) return;
        setCurrentModuleId(moduleId);
        setResourceType(type);
        setShowResourceModal(true);
    };

    const handleDeleteModule = (moduleId) => {
        if (!canEdit) return;
        setModuleToDelete(moduleId);
        setShowDeleteConfirmation(true);
    };

    const confirmDeleteModule = async () => {
        if (!canEdit) return;
        try {
            await api.delete(`/courses/${courseId}/modules/${moduleToDelete}`);
            setModules(modules.filter(m => m.id !== moduleToDelete));
            setShowDeleteConfirmation(false);
        } catch (err) {
            setError('Failed to delete module');
        }
    };

    const updateModuleResources = async (moduleId, updatedModule) => {
        setModules(prevModules =>
            prevModules.map(m =>
                m.id === moduleId ? { ...m, ...updatedModule } : m
            )
        );
    };

    const addNewModule = () => {
        if (!canEdit) return;
        const tempId = `temp-${Date.now()}`;
        const newModule = {
            id: tempId,
            type: 'module',
            title: '',
            order: modules.filter(m => m.type !== 'general').length + 1,
            resourceIds: [],
            isNew: true
        };
        setModules([...modules, newModule]);
    };

    const saveNewModule = async (tempId, title) => {
        try {
            const response = await api.post(`/courses/${courseId}/modules`, { title });
            const savedModule = response.data;
            setModules(prevModules =>
                prevModules.map(m =>
                    m.id === tempId ? { ...savedModule, isNew: false } : m
                )
            );
            return savedModule;
        } catch (err) {
            setError('Failed to save new module');
            throw err;
        }
    };

    const cancelNewModule = (tempId) => {
        setModules(prevModules => prevModules.filter(m => m.id !== tempId));
    };

    if (loading) return <Loading />;
    if (error) return <div className="text-center py-4 text-red-500">Error: {error}</div>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-8">{course?.title}</h1>

            <h2 className="text-xl mb-6">Course Modules:</h2>

            {modules.map((module, index) => (
                <Module
                    key={module.id}
                    module={module}
                    moduleNumber={module.type === 'general' ? null : index}
                    onAddResource={handleAddResource}
                    onDeleteModule={handleDeleteModule}
                    onCancelNewModule={cancelNewModule}
                    courseId={courseId}
                    updateModuleResources={updateModuleResources}
                    setError={setError}
                    saveNewModule={saveNewModule}
                    isNew={module.isNew}
                    userPermission={userPermission}
                />
            ))}

            {canEdit && (
                <button
                    onClick={addNewModule}
                    className="mt-6 p-3 text-lg font-semibold rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition duration-300 ease-in-out transform flex items-center justify-center"
                >
                    <Plus size={24} className="mr-2" />
                    New Module
                </button>
            )}

            <AddResourceModal
                show={showResourceModal}
                onClose={() => setShowResourceModal(false)}
                moduleId={currentModuleId}
                resourceType={resourceType}
                courseId={courseId}
                onResourceAdded={updateModuleResources}
                setError={setError}
                canEdit={canEdit}
            />

            <DeleteConfirmationModal
                show={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
                onConfirm={confirmDeleteModule}
            />
        </div>
    );
};

export default Dashboard;