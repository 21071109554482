import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './util/Loading';

const UserProfile = () => {
  const { user, logout, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="flex items-center">
      <img 
        src={user.picture || "/api/placeholder/40/40"} 
        alt="User" 
        className="w-10 h-10 rounded-full"
      />
      <div className="ml-2">
        <p className="font-semibold">{user.name || user.email}</p>
        <button 
          onClick={() => logout({ returnTo: window.location.origin })} 
          className="text-sm text-blue-600"
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default UserProfile;