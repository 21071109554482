import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Send, AlertCircle } from 'lucide-react';
import api from '../../api';
import MarkdownRenderer from '../MarkdownRenderer';

const Ask = () => {
  const { courseId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const moduleId = queryParams.get('moduleId');
  const resourceId = queryParams.get('resourceId');
  const pageStart = queryParams.get('pageStart');
  const pageEnd = queryParams.get('pageEnd');

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const messagesEndRef = useRef(null);

  const [filterInfo, setFilterInfo] = useState({});

  useEffect(() => {
    if (moduleId || resourceId) {
      fetchFilterInfo();
    }
  }, [courseId, moduleId, resourceId]);

  const fetchFilterInfo = async () => {
    try {
      let info = {};
      if (moduleId) {
        const moduleResponse = await api.get(`/courses/${courseId}/modules/${moduleId}`);
        info.module = moduleResponse.data.title;
      }
      if (resourceId) {
        const resourceResponse = await api.get(`/courses/${courseId}/resources/${resourceId}`);
        info.resource = resourceResponse.data.title;
      }
      setFilterInfo(info);
    } catch (err) {
      console.error('Error fetching filter information:', err);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    const newUserMessage = { type: 'user', content: inputValue };
    setMessages(prev => [...prev, newUserMessage]);
    setInputValue('');
    setLoading(true);
    setError('');

    try {
      const queryParams = { 
        query: inputValue,
      };

      if (moduleId || resourceId || (pageStart && pageEnd)) {
        queryParams.filters = {
          moduleId,
          resourceId,
          pageRange: pageStart && pageEnd ? { start: parseInt(pageStart), end: parseInt(pageEnd) } : null
        };
      }

      const response = await api.post(`/courses/${courseId}/query`, queryParams);

      const newBotMessage = {
        type: 'bot',
        content: response.data.answer,
        sources: response.data.sources
      };
      setMessages(prev => [...prev, newBotMessage]);
    } catch (err) {
      setError('Failed to process query: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  };

  const formatPageNumbers = (pages) => {
    const sortedPages = [...new Set(pages)].sort((a, b) => a - b);
    const ranges = [];
    let range = [sortedPages[0]];

    for (let i = 1; i < sortedPages.length; i++) {
      if (sortedPages[i] === sortedPages[i-1] + 1) {
        range.push(sortedPages[i]);
      } else {
        ranges.push(range);
        range = [sortedPages[i]];
      }
    }
    ranges.push(range);

    const formattedRanges = ranges.map(r => 
      r.length > 1 ? `${r[0]}-${r[r.length-1]}` : `${r[0]}`
    );

    return formattedRanges.join(', ');
  };

  const organizeResources = (sources) => {
    if (!sources) return [];
    const resourceMap = {};
    sources.forEach(source => {
      if (!resourceMap[source.resourceName]) {
        resourceMap[source.resourceName] = new Set();
      }
      for (let i = source.startPage; i <= source.endPage; i++) {
        resourceMap[source.resourceName].add(i);
      }
    });

    return Object.entries(resourceMap).map(([resourceName, pages]) => ({
      resourceName,
      pages: formatPageNumbers(Array.from(pages))
    }));
  };

  return (
    <div className="flex flex-col h-full bg-gray-50">
      {(moduleId || resourceId || (pageStart && pageEnd)) && (
        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm">
                Your query is restricted to:
                {filterInfo.module && <span className="font-medium"> Module: {filterInfo.module}</span>}
                {filterInfo.resource && <span className="font-medium"> Resource: {filterInfo.resource}</span>}
                {pageStart && pageEnd && (
                  <span className="font-medium"> Pages: {pageStart}-{pageEnd}</span>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="flex-grow overflow-y-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="max-w-3xl mx-auto space-y-6">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div className={`flex max-w-[80%] ${message.type === 'user' ? 'flex-row-reverse' : 'flex-row'}`}>
                <div
                  className={`p-4 rounded-lg ${
                    message.type === 'user' ? 'bg-blue-100 text-blue-900' : 'bg-white shadow-sm border border-gray-200'
                  }`}
                >
                  <MarkdownRenderer content={message.content} />
                  {message.sources && message.sources.length > 0 && (
                    <div className="mt-2 text-sm text-gray-600">
                      <strong>Sources:</strong>
                      <ul className="list-disc pl-5 mt-1">
                        {organizeResources(message.sources).map((resource, idx) => (
                          <li key={idx}>
                            {resource.resourceName} (pages {resource.pages})
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          {loading && (
            <div className="flex justify-start">
              <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          )}
          {error && <p className="text-red-600 text-center">{error}</p>}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="bg-white border-t border-gray-200 px-4 sm:px-6 lg:px-8 py-4">
        <div className="max-w-3xl mx-auto">
          <form onSubmit={handleSubmit} className="flex items-center">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Ask a question..."
              className="flex-grow p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              disabled={loading}
            />
            <button
              type="submit"
              disabled={loading}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Send size={20} className="mr-2" />
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Ask;