import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Home, Book, HelpCircle, Menu, X } from 'lucide-react';
import UserProfile from './UserProfile';

const Sidebar = ({ activeCourse }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => location.pathname.includes(path);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const closeSidebar = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(false);
    }
  };

  const handleNavigation = (to) => {
    navigate(to);
    closeSidebar();
  };

  const sidebarContent = (
    <>
      <div className="p-6">
        <h1 className="text-xl font-bold text-blue-600 truncate">
          {activeCourse || 'Cogniboom'}
        </h1>
      </div>
      <nav className="flex-1">
        <NavItem to="" icon={<Home size={20} />} text="Resources" active={isActive('/course') && !isActive('/learn') && !isActive('/progress') && !isActive('/ask')} onClick={() => handleNavigation('')} />
        <NavItem to="learn" icon={<Book size={20} />} text="Learn" active={isActive('/learn')} onClick={() => handleNavigation('learn')} />
        <NavItem to="ask" icon={<HelpCircle size={20} />} text="Ask" active={isActive('/ask')} onClick={() => handleNavigation('ask')} />
      </nav>
      <div className="p-4">
        <button
          onClick={() => {
            navigate('/dashboard/my-courses');
            closeSidebar();
          }}
          className="w-full flex items-center justify-center px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-300"
        >
          <Menu size={20} className="mr-2" />
          <span>Main Menu</span>
        </button>
      </div>
      <div className="p-4">
        <UserProfile />
      </div>
    </>
  );

  return (
    <>
      <button
        className="md:hidden fixed top-4 right-4 z-50 p-2"
        onClick={toggleSidebar}
      >
        {isOpen ? <X size={28} /> : <Menu size={28} />}
      </button>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          } md:hidden`}
        onClick={closeSidebar}
      />
      <div
        className={`fixed top-0 right-0 h-full bg-white shadow-md transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
          } md:relative md:translate-x-0 w-[90%] max-w-80 md:w-64 flex flex-col z-40`}
      >
        {sidebarContent}
      </div>
    </>
  );
};

const NavItem = ({ to, icon, text, active, onClick }) => {
  return (
    <button onClick={onClick} className={`flex items-center px-4 py-2 w-full text-left ${active ? 'bg-blue-100 text-blue-600' : 'text-gray-700 hover:bg-gray-200'}`}>
      {icon}
      <span className="ml-2">{text}</span>
    </button>
  );
};

export default Sidebar;